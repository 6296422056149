import React from "react";
import { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Flex, Button } from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import logo from "./logo.png";
import "react-toastify/dist/ReactToastify.css";
export const NavBar = ({ accounts, setAccounts, homeEvent, type }) => {
  function disconnect() {
    setAccounts([]);
  }
  const isConnected = Boolean(accounts[0]);

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      if (accounts && network.chainId === 1) {
        setAccounts(accounts);
      } else {
        toast(
          "Wrong network selected! You need to be connected to ethereum mainnet in order to download."
        );
      }
    } else {
      toast("You need a metamask wallet installed in order to download.");
    }
  }
  return (
    <div
      style={{
        zIndex: 2,
        width: "100vw",
        height: "80px",
        top: "0px",
        left: "0px",
        position: "fixed",
      }}
    >
      <Flex justify="space-around" width="100%">
        <div
          style={{
            marginTop: "30px",
            cursor: "pointer",
            fontSize: "20px",
            className: "text",
          }}
        >
          <a
            href="https://twitter.com/drknssBYsascha"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            Twitter
          </a>
        </div>
        <img
          src={logo}
          height="50px"
          onClick={homeEvent}
          style={{ marginTop: "15px" }}
        />
        <div className="marginOnPhone" style={{ marginTop: "30px" }}>
          <Button
            onClick={!isConnected ? connectAccount : disconnect}
            borderRadius="5px"
            backgroundColor="#55AEEF"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            fontFamily="inherit"
            color="white"
            fontSize="20px"
            maxWidth="100px"
            height="30px"
            cursor="pointer"
          >
            {!isConnected
              ? "Connect"
              : accounts[0].substring(0, 5) + "." + accounts[0].substring(40)}
          </Button>{" "}
        </div>
      </Flex>
      <ToastContainer />
    </div>
  );
};
