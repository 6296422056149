import React, { useRef, useEffect, useState } from "react";
import "./imageDownloader.css";

const traitMatrix = [
  [
    "Abel",
    "black-eye",
    "dark-make-up",
    "dark-war-paint",
    "dark",
    "ethereal",
    "ghoul",
    "greyscale",
    "Kain",
    "light-make-up",
    "light-war-paint",
    "light",
    "Midas",
    "moon",
    "pale-goth",
    "pale",
    "possessed",
  ],
  [
    "none",
    "AR_Device",
    "black-eye-mask",
    "blacksmith",
    "blade",
    "blue-mask",
    "eye-mod",
    "frameless-shades",
    "glass-glasses-ice",
    "glass-glasses-pink",
    "green-glasses",
    "John",
    "krang",
    "noggle-red",
    "oldschool-vr-device",
    "orange-glasses",
    "orange-mask",
    "orb-lense",
    "pink-glasses",
    "pink-wind-shield",
    "pirate",
    "purple-mask",
    "ready",
    "red-mask",
    "red-visor",
    "ridiculous-shades",
    "shades",
    "splinter",
    "steampunk-goggles",
    "The-70ies-called",
    "the-coolest-shades",
  ],
  ["none", "black-balaklava", "Luchadore", "pink-balaklava", "skull"],
  [
    "none",
    "diving-mask",
    "half-skull-mask",
    "plague",
    "x-ray-mask",
    "zone-mask",
  ],
  [
    "none",
    "anderson",
    "angelo",
    "black-beanie",
    "bro-cap",
    "bucket-hat",
    "Bun-ice",
    "Bun-purp",
    "Bun-red",
    "clockwork",
    "crocodile-hunter",
    "double-tails",
    "eddy-heat",
    "eddy-ice",
    "fade",
    "g-beanie",
    "Green-Buzz",
    "headband",
    "mohawk-green",
    "mohawk-red",
    "mullet",
    "paul",
    "Pink-Buzz",
    "ranger",
    "Red-Buzz",
    "ryan",
    "snapback-blue",
    "snapback-green",
    "snapback-red",
    "tomb-raider",
    "top-hat",
    "triple-mohawk-blue",
    "triple-mohawk-red",
    "walter",
    "wet-icey-iro",
    "wet-red-iro",
    "wild-braids-blue",
    "wild-braids-green",
    "wild-braids-red",
    "wild-long",
    "wild-red",
  ],
  [
    "none",
    "blue-bandana",
    "blunt",
    "cigarette",
    "full-beard-and-blunt",
    "full-beard-and-pipe",
    "pipe",
    "red-bandana",
    "short-stache",
    "tounge",
  ],
];

const ImageDownloader = () => {
  const canvasRef = useRef(null);
  const [traitTypes, setTraitTypes] = useState([
    { key: "bodys", value: 0 },
    { key: "eyes", value: 1 },
    { key: "full-face", value: 2 },
    { key: "face-mask", value: 3 },
    { key: "headwear", value: 4 },
    { key: "mouth", value: 5 },
  ]);
  const [traitType, setTrateType] = useState(0);
  const [traitName, setTrateName] = useState("bodys");
  const [nrOfTraits, setNrOfTraits] = useState([]);
  const draw = async (dsbody, dseyes, dsface, dsmask, dsheadwear, dsmouth) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image1 = new Image();
    image1.src = "./assets/Bodys/" + dsbody + ".png";
    await image1.decode();
    context.drawImage(image1, 0, 0, 500, 500);
    if (dsheadwear != "none") {
      const image5 = new Image();
      image5.src = "./assets/headwear/" + dsheadwear + ".png";
      await image5.decode();
      context.drawImage(image5, 0, 0, 500, 500);
    }
    if (dsmouth != "none") {
      const image6 = new Image();
      image6.src = "./assets/mouth/" + dsmouth + ".png";
      await image6.decode();
      context.drawImage(image6, 0, 0, 500, 500);
    }
    if (dseyes != "none") {
      const image2 = new Image();
      image2.src = "./assets/eyes/" + dseyes + ".png";
      await image2.decode();
      context.drawImage(image2, 0, 0, 500, 500);
    }
    if (dsmask != "none") {
      const image4 = new Image();
      image4.src = "./assets/face-mask/" + dsmask + ".png";
      await image4.decode();
      context.drawImage(image4, 0, 0, 500, 500);
    }
    if (dsface != "none") {
      const image3 = new Image();
      image3.src = "./assets/full-face/" + dsface + ".png";
      await image3.decode();
      context.drawImage(image3, 0, 0, 500, 500);
    }
  };
  useEffect(() => {
    setTrate(0, 0);
    // handleClear();
    // draw(body, eyes, face, mask, headwear, mouth);
    // draw((body = 'Abel'), (eyes = 'AR_Device'), (face = undefined), (mask = 'diving-mask'), (headwear = 'anderson'), (mouth = 'blunt'))
  }, []);

  function setTrate(value) {
    setTrateType(value);
    setTrateName(traitTypes.find((res) => res.value == value).key);
  }

  useEffect(() => {
    const onPageLoad = () => {
      const track = document.getElementById("image-track");
      window.onmousedown = (e) => {
        track.dataset.mouseDownAt = e.clientX;
      };
      window.onmouseup = (e) => {
        track.dataset.mouseDownAt = "0";
        track.dataset.prevPercentage = track.dataset.percentage;
      };
      window.onmousemove = (e) => {
        if (track.dataset.mouseDownAt == "0") return;
        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX;
        const maxDelta = window.innerWidth / 2;
        const percentage = (mouseDelta / maxDelta) * -100;
        let nextPercentage =
          parseFloat(track.dataset.prevPercentage) + percentage;
        nextPercentage = Math.min(nextPercentage, 0);
        nextPercentage = Math.max(nextPercentage, -100);

        track.dataset.percentage = nextPercentage;
        track.animate(
          { transform: `translate(${nextPercentage}%, -50%)` },
          { duration: 1200, fill: "forwards" }
        );
        for (let image of track.getElementsByClassName("image")) {
          image.animate(
            { objectPosition: `${100 + nextPercentage}% center` },
            { duration: 1200, fill: "forwards" }
          );
        }
      };
      //var canvas = document.getElementById("responsive-canvas");
      //var heightRatio = 1;
      //canvas.height = canvas.width * heightRatio;
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  function handleSelect(x, y) {
    setTraitTypes(
      traitTypes
        .map((res) => {
          if (res.value !== x) return res;
        })
        .filter((res) => res !== undefined)
    );
    console.log(traitTypes);
    setNrOfTraits((nrOfTraits) => [...nrOfTraits, [x, y]]);
  }
  const handleDownload = () => {
    const canvas = canvasRef.current;
    const imageData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "flawless.png";
    link.href = imageData;
    link.click();
  };
  const handleClear = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };
  return (
    <div>
      <div style={{ marginTop: "80px", position: "fixed" }}>
        <ul>
          <li>
            <label>1. Trait to edit: </label>
            {nrOfTraits.length > 0 ? (
              <label>
                {traitTypes[nrOfTraits[0][0]].key}
                {": "}
                {traitMatrix[nrOfTraits[0][0]][nrOfTraits[0][1]]}
              </label>
            ) : (
              <select onChange={(e) => setTrate(e.target.value)}>
                {traitTypes.map((type) => (
                  <option value={type.value}>{type.key}</option>
                ))}
              </select>
            )}
          </li>
          <li>
            <label>2. Trait to edit</label>
            {nrOfTraits.length > 1 ? (
              <label>
                {traitTypes[nrOfTraits[1][0]].key}
                {": "}
                {traitMatrix[nrOfTraits[1][0]][nrOfTraits[1][1]]}
              </label>
            ) : (
              <select
                onChange={(e) => setTrate(e.target.value)}
                disabled={nrOfTraits.length < 1}
              >
                {traitTypes.map((type) =>
                  nrOfTraits.every((res) => res[0] !== type.value) ? (
                    <option value={type.value}>{type.key}</option>
                  ) : (
                    ""
                  )
                )}
              </select>
            )}
          </li>
          <li data-trait-index="2">
            <label>3. Trait to edit</label>
            {nrOfTraits.length > 2 ? (
              <label>
                {traitTypes[nrOfTraits[2][0]].key}
                {": "}
                {traitMatrix[nrOfTraits[2][0]][nrOfTraits[2][1]]}
              </label>
            ) : (
              <select
                onChange={(e) => setTrate(e.target.value)}
                disabled={nrOfTraits.length < 2}
              >
                {traitTypes.map((type) =>
                  nrOfTraits.every((res) => res[0] !== type.value) ? (
                    <option value={type.value}>{type.key}</option>
                  ) : (
                    ""
                  )
                )}
              </select>
            )}
          </li>
          <li data-trait-index="2">
            <label>4. Trait to edit</label>
            {nrOfTraits.length > 3 ? (
              <label>
                {traitTypes[nrOfTraits[3][0]].key}
                {": "}
                {traitMatrix[nrOfTraits[3][0]][nrOfTraits[3][1]]}
              </label>
            ) : (
              <select
                onChange={(e) => setTrate(e.target.value)}
                disabled={nrOfTraits.length < 3}
              >
                {traitTypes.map((type) =>
                  nrOfTraits.every((res) => res[0] !== type.value) ? (
                    <option value={type.value}>{type.key}</option>
                  ) : (
                    ""
                  )
                )}
              </select>
            )}
          </li>
          <li data-trait-index="2">
            <label>5. Trait to edit</label>
            {nrOfTraits.length > 4 ? (
              <label>
                {traitTypes[nrOfTraits[4][0]].key}
                {": "}
                {traitMatrix[nrOfTraits[4][0]][nrOfTraits[4][1]]}
              </label>
            ) : (
              <select
                onChange={(e) => setTrate(e.target.value)}
                disabled={nrOfTraits.length < 3}
              >
                {traitTypes.map((type) =>
                  nrOfTraits.every((res) => res[0] !== type.value) ? (
                    <option value={type.value}>{type.key}</option>
                  ) : (
                    ""
                  )
                )}
              </select>
            )}
          </li>
        </ul>
      </div>
      <div
        id="image-track"
        data-mouse-down-at="0"
        data-prev-percentage="0"
        data-percentage="0"
      >
        {traitMatrix[traitType].map((type, index) =>
          type != "none" ? (
            <img
              className="image"
              draggable="false"
              onClick={(e) => handleSelect(traitType, index)}
              src={
                process.env.PUBLIC_URL + "/" + traitName + "/" + type + ".png"
              }
            />
          ) : (
            <a onClick={(e) => handleSelect(traitType, index)}>NONE</a>
          )
        )}{" "}
      </div>
    </div>
  );
};

export default ImageDownloader;
