import "./App.css";
import { useEffect, useState, Suspense, useRef } from "react";
import { useRoute, useLocation } from "wouter";
import ImageDownloader from "./imageDownloader";
import { NavBar } from "./NavBar";

import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useCursor,
  MeshReflectorMaterial,
  Image,
  Text,
  Environment,
} from "@react-three/drei";
import getUuid from "uuid-by-string";
import { toast } from "react-toastify";
import VT323 from "./VT323/VT323_Regular.json";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import bessfrenPngFile from "./VT323/amalie-steiness.gif";
import { position } from "@chakra-ui/react";

const GOLDENRATIO = 1;
const bessfrenPng = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmSqEKQTvnJNqqqszpUMTVgPMr1aoosBManzPzzwDF8MJ5/BESSFREN${
    id == 0 ? 100 : id
  }.png`;
const bessfrenVox = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmSumQ4bzPeBvroS7z2tLMy5trTmo8oj7kyjwZEGsH4n1D/BESSFREN${
    id == 0 ? 100 : id
  }.vox`;
const frenPng = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmYLupuVcmXxpu9WAymACyQuvEnMRRqGS1t1rYtEcsz1hU/FREN${id}.png`;
const frenVox = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmfKp3ujUvMo8CQyFKeYBKY8b7ZrStUQnKWwDr4P14dKEP/Crypto-Fren${id}.vox`;
const junkPng = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmYcQDJxfhetKwWZGeZ8zNpS4FcsoGx3GnPuWy7CPcXexP/JUNK00${
    !(id < 10) ? +id : "0" + +id
  }.png`;
const junkVOX = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmZAXDcf8WEfNYk4WWFfDsyDG5kBBaRrykHinBuioVioUg/JUNK${id}.vox`;
const pexelVrm = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/Qmebg7VMWTqe5h7WmQncCwzngc8UrYgi1kVnthoJwAmYk2/junk${
    !(id < 10) ? +id + 1 : "0" + (+id + 1)
  }.vrm`;
const pexelFBX = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmUtScjH2Dzb6R66nHoC731BL3ZBHu74JxZaSSwcXyrprd/junk${
    !(id < 10) ? +id + 1 : "0" + (+id + 1)
  }.fbx`;
const pexelImage = (id) =>
  `https://bessfrens.mypinata.cloud/ipfs/QmWfpMvxCKVtAc7x8WHpk3G3pHARJMososfDpQNcjWuaR1/${
    !(id < 10) ? +id + 1 : "0" + (+id + 1)
  }.png`;
function App(images) {
  const letters = "!§$%&/()=?´`^°+*#'<>-_.,;:";
  const [accounts, setAccounts] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [type, setType] = useState("");
  const [meme, setMeme] = useState(false);
  const [, setLocation] = useLocation();
  useEffect(() => {
    if (type == "flawless-renegades" && tokens.length > 0) setMeme(true);
  }, [tokens, type]);

  function setAccountsOverRide(newAccounts) {
    setTokens([]);
    setAccounts(newAccounts);
  }
  function resetTokens() {
    setTokens([]);
    setMeme(false);
    setType("");
    setLocation("/");
  }
  function fetchFile(url) {
    const parts = url.split(".");
    const ending = parts[parts.length - 1];
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${type}.${ending}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }
  async function getData(account, contract) {
    if (account) {
      setType(contract);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": "82e00182158941cfb0ef021aa3aace33",
        },
      };
      const response = await fetch(
        `https://api.opensea.io/api/v1/assets?owner=${account}&collection_slug=${contract}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Data coud not be fetched!");
      } else {
        const result = await response.json();
        const tokens = [];
        for (let i = 0; i < result.assets.length; i++) {
          if (contract == "crypto-junkies-genesis") {
            tokens.push({
              position: images[i].position,
              rotation: images[i].rotation,
              url: pexelImage(result.assets[i].token_id),
              vrm: pexelVrm(result.assets[i].token_id),
              fbx: pexelFBX(result.assets[i].token_id),
            });
          } else if (contract == "bessfrens") {
            tokens.push({
              position: images[i].position,
              rotation: images[i].rotation,
              url: bessfrenPng(result.assets[i].token_id),
              vox: bessfrenVox(result.assets[i].name.split("#")[1]),
              vrm: undefined,
              fbx: undefined,
            });
          } else if (contract == "crypto-frens") {
            tokens.push({
              position: images[i].position,
              rotation: images[i].rotation,
              url: frenPng(result.assets[i].name.split("#")[1]),
              vox: frenVox(result.assets[i].name.split("#")[1]),
              vrm: undefined,
              fbx: undefined,
            });
          } else if (contract == "crypto-junkies") {
            tokens.push({
              position: images[i].position,
              rotation: images[i].rotation,
              url: junkPng(result.assets[i].name.split("#")[1]),
              vox: junkVOX(result.assets[i].name.split("#")[1]),
              vrm: undefined,
              fbx: undefined,
            });
          } else if (contract == "flawless-renegades") {
            tokens.push("test");
          }
        }
        if (result.assets.length == 0)
          toast(`you are not holding any ${contract} in this wallet`);
        setTokens(tokens);
      }
    } else {
      toast("You need to connect your wallet in order to download your files");
    }
  }
  function Frames({
    images,
    q = new THREE.Quaternion(),
    p = new THREE.Vector3(),
  }) {
    const ref = useRef();
    const clicked = useRef();
    let counter = 0;
    const [, params] = useRoute("/item/:id");
    const [, setLocation] = useLocation();
    useEffect(() => {
      clicked.current = ref.current.getObjectByName(params?.id);
      if (clicked.current) {
        clicked.current.parent.updateWorldMatrix(true, true);
        clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25));
        clicked.current.parent.getWorldQuaternion(q);
      } else {
        p.set(0, 0, 5.5);
        q.identity();
      }
    });
    useFrame((state, dt) => {
      state.camera.position.lerp(p, 0.05);
      state.camera.quaternion.slerp(q, 0.05);
    });
    return (
      <group
        ref={ref}
        onClick={(e) => (
          e.stopPropagation(),
          setLocation(
            clicked.current === e.object ? "/" : "/item/" + e.object.name
          )
        )}
        onPointerMissed={() => setLocation("/")}
      >
        {images.map(
          (props) => <Frame key={props.url} {...props} /> /* prettier-ignore */
        )}
      </group>
    );
  }
  function getReneData(account, contract) {
    getData(account, contract);
  }
  function Frame({ url, c = new THREE.Color(), ...props }) {
    const [hovered, hover] = useState(false);
    const font = new FontLoader().parse(VT323);
    const [rnd] = useState(() => Math.random());
    const image = useRef();
    const frame = useRef();
    const name = getUuid(url);
    useCursor(hovered);
    useFrame((state) => {
      image.current.material.zoom = 1;
      image.current.scale.x = THREE.MathUtils.lerp(
        image.current.scale.x,
        0.85 * (hovered ? 0.85 : 1),
        0.1
      );
      image.current.scale.y = THREE.MathUtils.lerp(
        image.current.scale.y,
        0.9 * (hovered ? 0.905 : 1),
        0.1
      );
      frame.current.material.color.lerp(
        c.set(hovered ? "orange" : "white"),
        0.1
      );
    });
    return (
      <group {...props}>
        <mesh
          name={name}
          onPointerOver={(e) => (e.stopPropagation(), hover(true))}
          onPointerOut={() => hover(false)}
          scale={[1, GOLDENRATIO, 0.05]}
          position={[0, GOLDENRATIO / 2, 0]}
        >
          <boxGeometry />
          <meshStandardMaterial
            color="#151515"
            metalness={0.5}
            roughness={0.5}
            envMapIntensity={2}
          />
          <mesh
            ref={frame}
            raycast={() => null}
            scale={[0.9, 0.93, 0.9]}
            position={[0, 0, 0.2]}
          >
            <boxGeometry />
            <meshBasicMaterial toneMapped={false} fog={false} />
          </mesh>
          <Image
            raycast={() => null}
            ref={image}
            position={[0, 0, 0.7]}
            url={url}
          />
        </mesh>
        <Text
          onPointerOver={(e) => (e.stopPropagation(), hover(true))}
          onPointerOut={() => hover(false)}
          onClick={() => fetchFile(url)}
          maxWidth={0.1}
          anchorX="left"
          anchorY="top"
          position={[0.55, GOLDENRATIO, 0]}
          fontSize={0.05}
        >
          Download PNG
        </Text>
        {props.fbx ? (
          <Text
            onPointerOver={(e) => (e.stopPropagation(), hover(true))}
            onPointerOut={() => hover(false)}
            onClick={() => fetchFile(props.fbx)}
            maxWidth={0.1}
            anchorX="left"
            anchorY="top"
            position={[0.55, GOLDENRATIO - 0.25, 0]}
            fontSize={0.05}
          >
            Download FBX
          </Text>
        ) : (
          <Text
            onPointerOver={(e) => (e.stopPropagation(), hover(true))}
            onPointerOut={() => hover(false)}
            onClick={() => fetchFile(props.vox)}
            maxWidth={0.1}
            anchorX="left"
            anchorY="top"
            position={[0.55, GOLDENRATIO - 0.25, 0]}
            fontSize={0.05}
          >
            Download VOX
          </Text>
        )}
        {props.vrm ? (
          <Text
            onPointerOver={(e) => (e.stopPropagation(), hover(true))}
            onPointerOut={() => hover(false)}
            onClick={() => fetchFile(props.vrm)}
            maxWidth={0.1}
            anchorX="left"
            anchorY="top"
            position={[0.55, GOLDENRATIO - 0.5, 0]}
            fontSize={0.05}
          >
            Download VRM
          </Text>
        ) : (
          <></>
        )}
      </group>
    );
  }
  useEffect(() => {
    const onPageLoad = () => {
      const menu = document.getElementById("menu");
      Array.from(document.getElementsByClassName("menu-item")).forEach(
        (item, index) => {
          item.onmouseover = (event) => {
            menu.dataset.activeIndex = index;
            let iterations = 0;
            const interval = setInterval(() => {
              event.target.innerText = event.target.innerText
                .split("")
                .map((letter, index) => {
                  if (index < iterations)
                    return event.target.dataset.value[index];
                  else return letters[Math.floor(Math.random() * 26)];
                })
                .join("");

              if (iterations >= event.target.dataset.value.length)
                clearInterval(interval);
              iterations += 1;
            }, 30);
          };
        }
      );
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  if (!meme) {
    return (
      <div>
        <NavBar
          accounts={accounts}
          setAccounts={setAccountsOverRide}
          homeEvent={resetTokens}
          type={type}
        />
        <div>
          {!tokens[0] ? (
            <div className="App" id="menu">
              <div id="menu-items">
                <a
                  // onClick={() => getData(accounts[0], "crypto-junkies")}
                  className="menu-item"
                  data-value="UNDER CONSTRUCTION..."
                >
                  UNDER CONSTRUCTION...
                </a>
                {/* <a
                  onClick={() => getData(accounts[0], "crypto-frens")}
                  className="menu-item"
                  data-value="Crypto-Frens (VOX)"
                >
                  Crypto-Frens (VOX)
                </a>
                <a
                  onClick={() => getData(accounts[0], "bessfrens")}
                  className="menu-item"
                  data-value="Bessfrens (VOX)"
                >
                  Bessfrens (VOX)
                </a>
                <a
                  onClick={() => getData(accounts[0], "crypto-junkies-genesis")}
                  className="menu-item"
                  data-value="2D-Genesis Junkies (FBX,VRM,PNG)"
                >
                  2D-Genesis Junkies (FBX,VRM,PNG)
                </a>
                <a
                  onClick={() => getReneData(accounts[0], "flawless-renegades")}
                  className="menu-item"
                  data-value="Flawless Renegades (Meme generator)"
                >
                  Flawless Renegades (Meme generator)
                </a> */}
              </div>
              <div id="menu-background-pattern"></div>
              <div id="menu-background-image"></div>
            </div>
          ) : (
            <Suspense fallback={null}>
              <div>
                <div style={{ position: "absolute", width: "100%" }}>
                  <img
                    src={bessfrenPngFile}
                    width="20%"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  ></img>
                </div>
                <Canvas
                  gl={{ alpha: false }}
                  dpr={[1, 1.5]}
                  camera={{ fov: 70, position: [0, 2, 15] }}
                  style={{ height: "600px" }}
                >
                  <color attach="background" args={["#191920"]} />
                  <fog attach="fog" args={["#191920", 0, 15]} />
                  <Environment preset="city" />
                  <group position={[0, -0.5, 0]}>
                    <Frames images={tokens} />
                    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
                      <planeGeometry args={[50, 50]} />
                      <MeshReflectorMaterial
                        blur={[300, 100]}
                        resolution={2048}
                        mixBlur={1}
                        mixStrength={40}
                        roughness={1}
                        depthScale={1.2}
                        minDepthThreshold={0.4}
                        maxDepthThreshold={1.4}
                        color="#101010"
                        metalness={0.5}
                      />
                    </mesh>
                  </group>
                </Canvas>
              </div>
            </Suspense>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <NavBar
          accounts={accounts}
          setAccounts={setAccountsOverRide}
          homeEvent={resetTokens}
          type={type}
        />
        <div style={{ height: "100vh", width: "100vh" }}>
          <ImageDownloader></ImageDownloader>
        </div>
      </div>
    );
  }
}

export default App;
